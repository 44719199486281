<template>
  <div class="abt-container">
    <h3>intent</h3>
    <p>i actually use site for having background video (visual stimulation if you will) or when i'm on shrooms with buddies.</p>
    <p>most of the time i let music play in the background (wip).</p>
    <p>
      image sauce:
      <a href="https://archillect.com/tv" target="_blank">archillect.com/tv</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>
